import React from 'react';
import { Region } from '@enonic/react-components';

import './twoColumn.scss';
import { TwoColumnPropsType } from './type.d'

function TwoColumn(props: Readonly<TwoColumnPropsType>) {
  const { leftRegionData, leftRegionName, rightRegionData, rightRegionName, backgroundColor } = props

  return (
    <div className={`w-full ${backgroundColor}`}>
      <div className="l-container mx-auto">
        <div className="columns-2 mobile:columns-1 desktop:flex tablet:flex mobile:block break-all">
          <div className="w-full pr-sm mobile:pr-0">
            <Region name={leftRegionName} regionData={leftRegionData} />
          </div>
          <div className="w-full pl-sm mobile:pl-0">
            <Region name={rightRegionName} regionData={rightRegionData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default (props: Readonly<TwoColumnPropsType>) => <TwoColumn {...props} />;
